import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import logo from '../Assets/images/Logo.png'

const CallPage = () => {
  const { id } = useParams(); // Extract call ID from URL
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [pc, setPC] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  useEffect(() => {
    const initializeFirebase = () => {
      const firebaseConfig = {
        apiKey: "AIzaSyCxsZjE8xqTltmPyzMhTC3Ho1BvupBUu6Q",
        authDomain: "vediocallapp-bc493.firebaseapp.com",
        projectId: "vediocallapp-bc493",
        storageBucket: "vediocallapp-bc493.appspot.com",
        messagingSenderId: "481852095636",
        appId: "1:481852095636:web:aa2e66ca3daeeebe6a0881",
      };

      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }
      setInitialized(true);
    };

    initializeFirebase();
  }, []);

  useEffect(() => {
    const initializeCall = async () => {
      const firestore = firebase.firestore();
      const callDoc = firestore.collection("calls").doc(id);
      const answerCandidates = callDoc.collection("answerCandidates");
      const offerCandidates = callDoc.collection("offerCandidates");

      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio:  {
            echoCancellation: true,
            noiseSuppression: true,
          },
      });
      setLocalStream(stream);

      const newPC = new RTCPeerConnection({
        iceServers: [
          {
            urls: [
              "stun:stun1.l.google.com:19302",
              "stun:stun2.l.google.com:19302",
            ],
          },
        ],
        iceCandidatePoolSize: 10,
      });

      newPC.ontrack = (event) => {
        if (event.streams && event.streams[0]) {
          setRemoteStream(event.streams[0]);
        }
      };

      stream.getTracks().forEach((track) => {
        newPC.addTrack(track, stream);
      });

      newPC.onicecandidate = (event) => {
        event.candidate && answerCandidates.add(event.candidate.toJSON());
      };

      const callData = (await callDoc.get()).data();
      const offerDescription = callData.offer;
      await newPC.setRemoteDescription(new RTCSessionDescription(offerDescription));

      const answerDescription = await newPC.createAnswer();
      await newPC.setLocalDescription(answerDescription);

      const answer = {
        type: answerDescription.type,
        sdp: answerDescription.sdp,
      };

      await callDoc.update({ answer });

      offerCandidates.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const candidate = new RTCIceCandidate(change.doc.data());
            newPC.addIceCandidate(candidate);
          }
        });
      });

      setPC(newPC);
    };

    initializeCall();
  }, [id]);
  const handleHangUp = () => {
    if (localStream) {
      localStream.getTracks().forEach(track => track.stop());
    }
    if (pc) {
      pc.close();
    }
    setCallEnded(true);
  };
  return (
    
     <div className="container mt-4">
     {/* <div className="section-border"> */}
     {!callEnded ? (<div className="row">
     
         <div className="col-md-11">
           <div className="video-section">
             <div className="row">
               <div className="col-md-6 col-sm-6">
                 <div className="d-flex mt-3">
                   <div className="bg-secondary mx-3 heading-text " style={{display:"flex",justifyContent:"center",alignItems:'center'}}>
                    <img src={logo} style={{height:"33px"}}/>
                   </div>
                   <div className="mt-2 heading-video">
                     <h6>Welcome to your Anchorstech Video Call</h6>
                   </div>
                
                 </div>
                 <hr></hr>
               </div>
               <div className="col-md-6 col-sm-6">
                 <div>
                  
                 </div>
               </div>
             </div>
             <div className="row">
               <div className="col-6">
               <video
          ref={(ref) => {
            if (ref) {
              ref.srcObject = localStream;
              ref.muted = true;
            }
          }}
       
          autoPlay
          playsInline
        ></video>
               </div>
               <div className="col-6">
                 <div>
                 <video
          ref={(ref) => {
            if (ref) {
              ref.srcObject = remoteStream;
             
            }
          }}
        
          autoPlay
          playsInline
        ></video>
                 </div>
               </div>
             </div>
             <div className="d-flex" style={{ marginLeft: "30px" }}>
               <div>
                 
               </div>
             </div>
             <div className="mx-4 ">
              
               <div>
                 <div className="d-flex justify-content-between mb-2">
                   <span>
             
 

                   </span>
                   <div className="hangup">
                   <button onClick={handleHangUp} className="btn btn-danger">
                        Hang Up
                      </button>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       {/* </div> */}
     </div>
     ):(
        <div className="thank-you-message" style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
          <h2>Thank you for using Anchorstech Video Call!</h2>
        </div>
      )}
   </div>
  );
};

export default CallPage;
